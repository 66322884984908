import * as React from "react"
import { LayoutFlat } from "../../components/flat/layout"
import ContentMediaFlat from "../../components/flat/content-media"
import LogosFlat from "../../components/flat/logos"
import BlogLatestFlat from "../../components/flat/blog-latest"
import CallToActionFlat from "../../components/flat/call-to-action"
import MastheadCarouselFlat from "../../components/flat/masthead-carousel";
import TestimonialCarouselFlat from "../../components/flat/testimonial-carousel";
import HowItWorksCarouselFlat from "../../components/flat/how-it-works-carousel";

const IndexPageFlat = () => (
    <LayoutFlat>
        <MastheadCarouselFlat />
        <LogosFlat />
        <ContentMediaFlat cssModifier="c-content-media--invert" />
        <TestimonialCarouselFlat />
        <BlogLatestFlat heading="Customer Stories" />
        <CallToActionFlat />
        <HowItWorksCarouselFlat />
        <BlogLatestFlat heading="Our Insights" />
    </LayoutFlat>
)

export default IndexPageFlat
